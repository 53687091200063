import { ValidationErrors, ValidatorFn, AbstractControl } from '@angular/forms';

export const PasswordMatchValidator: ValidatorFn = (formGroup: AbstractControl): ValidationErrors | null => {
  const passwordControl = formGroup.get('password');
  const confirmPasswordControl = formGroup.get('confirmPassword');

  if (!passwordControl || !confirmPasswordControl) {
    return null;
  }

  const password = passwordControl.value;
  const confirmPassword = confirmPasswordControl.value;

  if (confirmPasswordControl.errors && !confirmPasswordControl.errors['passwordMismatch']) {
    return null;
  }

  if (confirmPasswordControl.dirty && password !== confirmPassword) {
    confirmPasswordControl.setErrors({ passwordMismatch: true });
  } else {
    confirmPasswordControl.setErrors(null);
  }

  return null;
};
